<template>
  <footer class="footer__container">
    <call-to-action />

    <div class="footer-inner__container">
      <footer-primary-menu :data="footerMenuPrimary">
        <template #pre>
          <figure class="logo__figure">
            <nuxt-picture alt="AFV logo" src="/logo.svg" />
          </figure>
        </template>

        <template #post>
          <app-button
            :url="localePath('/waitlist')"
            class="waitlist-button"
            small
            variant="primary"
          >
            {{ $t("waitlist.joinWaitlist") }}
          </app-button>
        </template>
      </footer-primary-menu>

      <footer-contact-details
        v-if="footer?.contact_details"
        :data="footer.contact_details"
      />

      <footer-columns v-if="footer?.columns?.length" :data="footer.columns" />

      <footer-primary-menu :data="footerMenuSecondary">
        <template #pre>
          <p>©2023 Finance Valley. All rights reserved.</p>
        </template>
      </footer-primary-menu>
    </div>
  </footer>
</template>

<script lang="ts" setup>
import { useLocalePath } from "#imports";
import { storeToRefs } from "pinia";
import AppButton from "~/modules/shared/components/AppButton.vue";
import CallToAction from "~/modules/shared/components/CallToAction.vue";
import FooterColumns from "~/modules/shared/components/footer/FooterColumns.vue";
import FooterContactDetails from "~/modules/shared/components/footer/FooterContactDetails.vue";
import FooterPrimaryMenu from "~/modules/shared/components/footer/FooterPrimaryMenu.vue";
import { useGlobalStore } from "~/modules/shared/stores/global";

const globalStore = useGlobalStore();
const localePath = useLocalePath();

const { footer, footerMenuPrimary, footerMenuSecondary } =
  storeToRefs(globalStore);
</script>

<style lang="scss" scoped>
.footer__container {
  @apply mt-6 md:mt-12 pb-4;

  .footer-inner__container {
    @apply max-w-custom flex flex-col mx-auto py-6 md:py-12 px-8 md:px-16 lg:px-36;
  }

  .logo__figure {
    @apply w-72 mt-10 md:mt-0 md:mr-10 xl:mr-auto;

    :deep {
      img {
        @apply w-full;
        object-fit: contain;
      }
    }
  }

  .waitlist-button {
    @apply w-full md:w-auto mt-20 md:mt-0 md:ml-10 xl:ml-20;
  }
}
</style>

<template>
  <dropdown-wrapper with-chevron>
    <template #label>
      <figure class="locale__figure">
        <nuxt-picture
          :alt="`${locale} locale flag`"
          :src="`/flag_${locale}.jpg`"
          class="figure__picture"
        />
      </figure>

      {{ activeLocale?.name }}
    </template>

    <ul class="items__list">
      <li
        v-for="availableLocale in availableLocales"
        :key="`locale-${availableLocale.code}`"
        class="list__item"
      >
        <a :href="availableLocale.url" class="item__link">
          <figure class="locale__figure">
            <nuxt-picture
              :alt="`${availableLocale.code} locale flag`"
              :src="`/flag_${availableLocale.code}.jpg`"
              class="figure__picture"
            />
          </figure>

          {{ availableLocale.name }}
        </a>
      </li>
    </ul>
  </dropdown-wrapper>
</template>

<script lang="ts" setup>
import { storeToRefs } from "pinia";
import DropdownWrapper from "~/modules/shared/components/DropdownWrapper.vue";
import { usePageLocaleStore } from "~/modules/shared/stores/locale";

const { locale, locales } = useI18n();
const localeRoute = useLocaleRoute();
const switchLocalePath = useSwitchLocalePath();
const pageLocaleStore = usePageLocaleStore();

const { pageLocales } = storeToRefs(pageLocaleStore);

const activeLocale = computed(() =>
  locales.value.find(({ code }) => code === locale.value),
);
const availableLocales = computed(() => {
  return locales.value
    .filter((i) => i.code !== locale.value)
    .map((locale) => {
      const pageLocale = pageLocales.value?.find(
        ({ attributes }) => attributes.locale === locale.code,
      );
      const route = localeRoute(
        {
          params: {
            slug: pageLocale?.attributes.slug,
          },
        },
        locale.code,
      );
      const url = pageLocale
        ? `${window.location.protocol}//${locale.domain || ""}${route?.fullPath}`
        : switchLocalePath(locale.code);

      return {
        ...locale,
        url,
      };
    });
});
</script>

<style lang="scss" scoped>
@import "~/assets/styles/mixins";

.lang-selector__container {
  .locale__figure {
    @apply mr-2 hidden xl:block;

    .figure__picture {
      @apply block w-6 h-6 rounded-full overflow-hidden;
    }

    :deep {
      img {
        @apply block w-full h-full;
        object-fit: cover;
      }
    }
  }

  .item__link {
    @apply relative w-full flex items-center px-4 py-2 text-body-xxs;

    &::before {
      @apply absolute w-full h-full top-0 left-0 opacity-0 rounded-xl;
      @include gradient();
      content: "";
      transition: 0.4s;
    }

    &:hover::before {
      @apply opacity-10;
    }
  }
}
</style>
